import '../../Styles/Prod.css'

export default function Pop5() {
    return (
        <div className="Pop5">
            <div className="Pop5TitleSct">
                <p className="Pop5Title">
                    POURQUOI NE PAS NOUS RENCONTRER ?
                </p>
            </div>
            <div className="Pop5Infos">
                <div className="Pop5TitleInfosSct">
                    <p className="Pop5TitleInfos">Coordonnées</p>
                </div>
                <div className="Pop5DescInfos">
                    <p>CONTACT-INFO@LIZA.COM</p>
                    <p>+33 0669599912</p>
                    <p>+33 0621192269</p>
                </div>
            </div>
        </div>
    )
}

Pop5()
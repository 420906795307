import MainProdImg from '../../Assets/ProdPage/Image 1.png'

export default function ProdMain() {
    return (
        <div className="ProdMain">
            <div className="ProdMainPicSct">
                <div className="MainPic">
                    <img src={MainProdImg} alt="" id='MainPic' />
                </div>
                <div className="SecPic">
                    <img src={MainProdImg} alt="" id='SecPic'/>
                    <img src={MainProdImg} alt="" id='SecPic'/>
                    <img src={MainProdImg} alt="" id='SecPic'/>
                </div>
            </div>
            <div className="ProdMainInfoSct">
                <p className="ProdMainTitle">
                    LIZA VOUS PROPOSE LE RIZ: <br />DÉLICE
                </p>
                <p className="ProdMainDesc">
                    Le riz "DÉLICE" est un riz naturellement parfumé, produit au Togo dans le respect de l'environnement. Il est utilisé pour l'alimentation humaine avec des préparations variées. <br /><br />
                    Il est certifié par l'ITRA, une institut de recherche agronomique. Il existe en plusieurs conditionnemnts, dont le riz blanc long grain de 5kg, 25kg, 1kg et 5 x 5kg.
                </p>
                <a className="MoreBtn">
                    EN SAVOIR PLUS
                </a>
            </div>
        </div>
    )
}

ProdMain();
import '../../Styles/Prod.css'
import ProdMain from './Prod1'
import Prod2 from './Prod2'
import Prod3 from './Prod3'
import Prod5 from './Prod5'
import Footer from '../Footer'

export default function Prod() {
    return (
        <div className="Prod">
            <ProdMain />
            <Prod2 />
            <Prod3 />
            <Prod5 />
            <Footer />
        </div>
    )   
}

Prod()
export default function Pop4() {
    return (
        <div className="Pop4">
            <div className="Pop4TitleSct">
                <p className="Pop4Title">
                    POURQUOI NE PAS NOUS RENCONTRER ?
                </p>
            </div>
            <div className="Pop4Infos">
                <div className="Pop4TitleInfosSct">
                    <p className="Pop4TitleInfos">Coordonnées</p>
                </div>
                <div className="Pop4DescInfos">
                    <p>CONTACT-INFO@LIZA.COM</p>
                    <p>+33 0669599912</p>
                    <p>+33 0621192269</p>
                </div>
            </div>
        </div>
    )
}

Pop4()
import '../Styles/Footer.css'

export default function Footer() {
    return (
        <div className="Footer">
            
        </div>
    )
}

Footer()
import '../../Styles/Pop.css'
import Corn from '../../Assets/Products/Corn.jpg'
import Milk from '../../Assets/Products/Milk.jpg'
import Rice from '../../Assets/Products/Rice.jpg'
import Rice2 from '../../Assets/Products/Rice2.jpg'
import Rice3 from '../../Assets/Products/Rice3.jpg'
import Rice4 from '../../Assets/Products/Rice4.jpg'
import Salt from '../../Assets/Products/Salt.jpg'
import Salt2 from '../../Assets/Products/Salt2.jpg'

import { Link } from "react-router-dom"

export default function Pop3() {
    return (
        <div className="Pop3">
            <div className="Pop3TitleSct">
                <p className="Pop3Title">NOS PRODUITS</p>
            </div>
            <div className="Pop3GridSct">
                <div className="GridImg1" id="GridImg"></div>
                <div className="GridImg2" id="GridImg"></div>
                <div className="GridImg3" id="GridImg"></div>
                <div className="GridImg4" id="GridImg"></div>
                <div className="GridImg5" id="GridImg"></div>
                <div className="GridImg6" id="GridImg"></div>
                <div className="GridImg7" id="GridImg"></div>
                <div className="GridImg8" id="GridImg"></div>
            </div>
            <div className="Pop3BtnSct">
                <a className="Pop3ProdBtn" href="">
                    <Link to="/products">   
                    <p className="Pop3LabelBtn">DÉCOUVRIR NOS PRODUITS</p>
                    </Link>
                </a>
            </div>
        </div>
    )
}

Pop3()
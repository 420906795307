import '../Styles/App.css';
import Navbar from '../Components/Navbar'
import ProdMain from '../Components/Prod/Prod';
import Infos from '../Components/Infos/Infos'
import Pop from '../Components/Pop/Pop'

import { Routes, Route } from 'react-router-dom';

function App() {
  return (
      <div className="App">
        <Navbar />
        <Routes>
          <Route path='/' Component={Pop}></Route>
          <Route path='/products' Component={ProdMain}></Route>
          <Route path='/about' Component={Infos}></Route>
          {/* <Route path='/contact' Component={Contact}></Route> */}
        </Routes>
      </div>
    );
}

export default App;

import '../../Styles/Pop.css'
import RiceImg from '../../Assets/riceGrey.jpg'

export default function PopMain() {
   return (
        <div className="PopMain">
            <div className="PopMainSct">
                <div className="PopMainInfosSct">
                    <div className="PopMainInfos">
                        <h1 className='PopMainTitle'>LIZA</h1>
                        <h2 className='PopMainDesc'>Avec LIZA, découvrez les richesses du Togo à travers ses produits d'exportation. LIZA vous propose des produits sains, naturels et authentiques, issus de l'agriculture et de l'artisanat togolais.</h2>
                        <a className="PopMainInfosBtn" href="">
                            <p className='PopMainBtnLabel'>EN SAVOIR PLUS</p>
                        </a>
                    </div>
                </div>
                <div className="PopMainPicSct">
                    <img src={RiceImg} alt="RiceImg" className='PopMainPic' />
                </div>
            </div>
            <div className="PopMainScrollSct">
                <p className='PopMainScroll'>.......</p>
            </div>
        </div>
    )
}

PopMain()
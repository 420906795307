import '../../Styles/Pop.css'
import RiceImg from '../../Assets/riceBlue.jpg'

export default function Pop2() {
    return (
        <div className="Pop2">
            <div className="Pop2ImgSct">
                <img src={RiceImg} alt="RiceImg" className='Pop2Img'/>
            </div>
            <div className="Pop2InfosSct">
                <div className="Pop2Infos">
                    <p className='Pop2Title'>LIZA UNE ENTREPRISE QUI VOUS RAPPROCHE DES AUTRES</p>
                    <div className="Pop2Desc">
                        <p className='Pop2Desc1'>LIZA SAS exporte vers l'Europe des produits togolais issus du développement durable. Elle est fondée par Mr ABITOR KOMI, un ingénieur et un acteur du changement au Togo et au Bénin.</p>
                        <p className='Pop2Desc2'>La satisfaction de nos clients est la couronne de notre activité</p>
                    </div>
                    <a className="Pop2InfosBtn" href="">
                        <p className='Pop2BtnLabel'>EN SAVOIR PLUS</p>
                    </a>
                </div>
            </div>
        </div>
    )   
}

Pop2()
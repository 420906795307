import ProdMainImg from '../../Assets/ProdPage/Image 1.png'

export default function Prod3() {
    return (
        <div className="Prod3">
            <div className="ProdMainPicSct">
                <div className="MainPic">
                    <img src={ProdMainImg} alt="" id='MainPic' />
                </div>
                <div className="SecPic">
                    <img src={ProdMainImg} alt="" id='SecPic'/>
                    <img src={ProdMainImg} alt="" id='SecPic2'/>
                    <img src={ProdMainImg} alt="" id='SecPic2'/>
                </div>
            </div>
            <div className="ProdMainInfoSct">
                <p className="ProdMainTitle">
                    LIZA VOUS PROPOSE DU MIEL<br />
                </p>
                <p className="ProdMainDesc">
                    Le miel proposé est un produit togolais fait par des apiculteurs organisés en coopératives. Il est certifié par l'ITRA, un institut de recherche agronomique.
                    <br /><br />
                    Il est issu d'une apiculture respecteuse de l'environnement, qui protège les sols, la biodiversité et les ressources en eau. Il existe plusieurs formes grand et moyen.
                </p>
                <a className="MoreBtn">
                    EN SAVOIR PLUS
                </a>
            </div>
        </div>
    )
}

Prod3();
import '../../Styles/Pop.css'
import Pop1 from './Pop1'
import Pop2 from './Pop2'
import Pop3 from './Pop3'
import Pop4 from './Pop4'
import Footer from '../Footer'

function Pop() {
    return (
        <div className="Pop">
            <Pop1 />
            <Pop2 />
            <Pop3 />
            <Pop4 />
            <Footer />
        </div>
    )
}

export default Pop;
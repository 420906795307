import '../../Styles/Infos.css'
import Logo from '../../Assets/LogoWhite.png'
import Prod5 from '../Prod/Prod5'
import Footer from '../Footer'

export default function Infos() {
    return (
        <div className="InfosMainSct">
            <div className="InfosSct">
                <img src={Logo} alt="" id='LogoSct'/>
                <p className='InfosTitle'>
                    UNE ENTREPRISE QUI VOUS RAPPROCHE DES AUTRES
                </p>
                <p className='InfosDesc'>
                    LIZA SAS est une entreprise qui a été fondée par Mr ABITOR KOMI, un ingénieur agroéconomiste de renom au Togo, qui possède une solide expertise dans le domaine du développement rural.
                    En plus de son diplôme, il est le fondateur de l'ONG Entreprise Territoire et Développement (ETD), qui définit et met en oeuvre des programmes de développement innovants et participatifs, qui contribuent à la création, à la répartition équitable et à la valorisation de la richesse entre les acteurs dans les secteurs de l'agriculture, de la gouvernance locale, de la gestion des ressources naturelles et de l'environnement.
                    <br /><br />
                    Son approche met au centre des actions la logique économique, en tenant compte des besoins et des aspirations des populations locales. Elle est déployée au Togo et au Bénin, où elle a permis de renforcer les capacités des acteurs locaux et de favoriser le dialogue entre les différents niveaux de gouvernance.
                    Au-delà de ETD, il est l'auteur des Entreprises de Services et Organisations de Producteurs (ESOP), qui sont un modèle d'entreprise sociale qui met en place une alliance commerciale et institutionnelle entre un entrepreneur privé et des producteurs organisés en coopératives ou en associations, en vue de satisfaire au mieux à des demandes peu ou pas satisfaites sur le marché.
                    <br /><br />
                    Elle a pour objectif de connecter durablement les producteurs à des marchés rémunérateursm en leur offrant des services de qualité, adaptés à leurs besoins et À leurs contraintes, en vue d'augmenter et sécuriser leurs revenus, tout en respectant les principes du développement durable.
                    De toutes ces initiatives sont nées des produits de qualité, comme le Riz délice, etc., qui ont été mis sur le marché Africain et qui ont rencontré un grand succès auprès des consommateurs.
                    <br /><br />
                    Après avoir réussi sur le marché Africain, il se lance pour défi de faire découvrir ses produits au continent européen, où il y a une forte demande pour des produits issus de l'agriculture biologique et équitable.
                    C'est dans cette perspective qu'il a créé la société LIZA SAS, qui est une entreprise spécialisée dans la vente de produits des entreprises de Mr Komi. LIZA SAS propose aux clients européens des produits de qualité, sains, savoureux et respectueux de l'environnement, qui valorisent le savoir-faire et le potentiel des producteurs africains.    
                </p>
            </div>
            <div className="Footer2">
                <Prod5 />
                <Footer />
            </div>
        </div>
    )
}

Infos()
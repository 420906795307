import "../Styles/Navbar.css"
import Logo from '../Assets/Logo.png'

import { Link } from 'react-router-dom';

export default function Navbar() {
    return (
        <div className="Navbar">
            <div className="NavbarLogo">
                <img src={Logo} alt="LizaLogo" className="LogoImg" />
            </div>
            <div className="NavbarBtn">
                <Link to="/"><p className="Btn">ACCUEIL</p></Link>
                <Link to="/products"><a href="" className="Btn">PRODUITS</a></Link>
                {/* <a href="" className="Btn">CATÉGORIES</a> */}
                <Link to="/about"><a href="" className="Btn">A PROPOS DE NOUS</a></Link>
                <Link to="/contact"><a href="" className="Btn">CONTACT</a></Link>
                <select name="" id="SelectSct">
                    <option value="ACCUEIL" id="SelectLabel"><Link to="/">ACCUEIL</Link></option>
                    <option value="ACCUEIL" id="SelectLabel"><Link to="/products">PRODUITS</Link></option>
                    <option value="CATEGORIES" id="SelectLabel">CATEGORIES</option>
                    <option value="À PROPOS DE NOUS" dir="SelectLabel">A PROPOS DE NOUS</option>
                    <option value="CONTACT" id="SelectLabel">CONTACT</option>
                </select>
            </div>
        </div>
    )
}

Navbar()
import Prod2Img from '../../Assets/ProdPage/Image 2.png'

export default function Prod2() {
    return (
        <div className="Prod2">
            <div className="Prod2PicSct">
                <div className="MainPic">
                    <img src={Prod2Img} alt="" id='MainPic' />
                </div>
                <div className="SecPic">
                    <img src={Prod2Img} alt="" id='SecPic'/>
                    <img src={Prod2Img} alt="" id='SecPic2'/>
                    <img src={Prod2Img} alt="" id='SecPic2'/>
                </div>
            </div>
            <div className="Prod2InfoSct">
                <p className="Prod2Title">
                    LIZA VOUS PROPOSE LE SOJA: <br />SOJAVIE
                </p>
                <p className="Prod2Desc">
                    DSOJAVIE est une farine de sevrage, produite au Togo par l'ESOP SOJA Nyo. Elle est riche en protéines et en énergie, et elle lutte contre la malnutrition chez les enfants de 6 à 12 mois. Elle est certifiée par l'ITRA, un institut de recherche agronomique. <br /><br />
                    Elle est issue d'une agriculture écologique, qui respecte la fertilité des sols, la biodiversité et les ressources en eau. La marque de la farine est "SOJAVIE". Elle existe sous deux formes: une pour les adultes et une pour les bébés.
                </p>
                <a className="MoreBtn2">
                    EN SAVOIR PLUS
                </a>
            </div>
        </div>
    )
}

Prod2();